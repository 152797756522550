import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import BasicSnackbar from "@/components/BasicSnackbar.vue";
import { useSnackbar } from "@/store";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { snackbar, snackbarColor, snackbarMsg } = storeToRefs(useSnackbar());

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(BasicSnackbar, {
        modelValue: _unref(snackbar),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(snackbar) ? (snackbar).value = $event : null)),
        color: _unref(snackbarColor),
        message: _unref(snackbarMsg)
      }, null, 8, ["modelValue", "color", "message"])
    ]),
    _: 1
  }))
}
}

})