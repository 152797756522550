import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'BasicSnackbar',
  props: /*@__PURE__*/_mergeModels({
    color: {},
    message: {}
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const model = _useModel<boolean>(__props, "modelValue"); // eslint-disable-line

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
    location: "bottom",
    color: _ctx.color
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        variant: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (model.value = false)),
        icon: "mdi-close"
      })
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.message) + " ", 1)
    ]),
    _: 1
  }, 8, ["modelValue", "color"]))
}
}

})