import { RouteRecordRaw } from "vue-router";
// import FieldsMapView from "@/modules/fields/mapview/FieldsMapView.vue";
// import LoginView from "@/modules/users/LoginView.vue";
// import NavbarView from "@/components/NavbarView.vue";
// import UsersView from "@/modules/users/UsersView.vue";
// import AdminView from "@/modules/admin/AdminView.vue";
// import AdminOrganizationsView from "@/modules/admin/AdminOrganizationsView.vue";
// import AdminUsersView from "@/modules/admin/AdminUsersView.vue";
// import AdminHomeView from "@/modules/admin/AdminHomeView.vue";
// import EquipmentsView from "@/modules/admin/application/EquipmentsView.vue";
// import ProductsView from "@/modules/admin/application/ProductsView.vue";
// import AdminHierarchiesView from "@/modules/admin/AdminHierarchiesView.vue";
import { useUsersStore } from "@/modules/users/store";
// import ActivitiesView from "@/modules/activities/ActivitiesView.vue";
// import AdminStationsView from "@/modules/admin/AdminStationsView.vue";
// import AdminOthersView from "@/modules/admin/others/AdminOthersView.vue";
// import AnalysesResultsViewVue from "@/modules/analysis-results/AnalysesResultsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    component: () => import("@/components/NavbarView.vue"),
    redirect: "map",
    children: [
      {
        path: "",
        name: "map",
        component: () => import("@/modules/fields/mapview/FieldsMapView.vue"),
        meta: {
          title: "Mapa | Arbo Monitor",
        },
      },
      {
        path: "usuarios",
        name: "users",
        component: () => import("@/modules/users/UsersView.vue"),
        meta: {
          title: "Usuários | Arbo Monitor",
        },
        beforeEnter: (to, from) => {
          const userStore = useUsersStore();
          if (userStore.canUsers) return true;
          return from;
        },
      },
      {
        path: "atividades",
        name: "activities",
        component: () => import("@/modules/activities/ActivitiesView.vue"),
        meta: {
          title: "Atividades | Arbo Monitor",
        },
      },
      {
        path: "analises",
        name: "analysis-results",
        component: () =>
          import("@/modules/analysis-results/AnalysesResultsView.vue"),
        meta: {
          title: "Análises | Arbo Monitor",
        },
        beforeEnter: (to, from) => {
          const userStore = useUsersStore();
          if (userStore.canAnalysisResults) return true;
          return from;
        },
      },
      {
        path: "admin",
        name: "admin",
        component: () => import("@/modules/admin/AdminView.vue"),
        beforeEnter: (to, from) => {
          const userStore = useUsersStore();
          if (userStore.userMe?.is_admin) return true;
          return from;
        },
        meta: {
          title: "Admin | Arbo Monitor",
        },
        children: [
          {
            path: "",
            name: "admin.home",
            component: () => import("@/modules/admin/AdminHomeView.vue"),
            meta: {
              title: "Admin | Arbo Monitor",
            },
          },
          {
            path: "organizacoes",
            name: "admin.organizations",
            component: () =>
              import("@/modules/admin/AdminOrganizationsView.vue"),
            meta: {
              title: "Organizações | Admin | Arbo Monitor",
            },
          },
          {
            path: "hierarquias",
            name: "admin.hierarchies",
            component: () => import("@/modules/admin/AdminHierarchiesView.vue"),
            meta: {
              title: "Hierarquias | Admin | Arbo Monitor",
            },
          },
          {
            path: "usuarios",
            name: "admin.users",
            component: () => import("@/modules/admin/AdminUsersView.vue"),
            meta: {
              title: "Usuários | Admin | Arbo Monitor",
            },
          },
          {
            path: "products",
            name: "admin.products",
            component: () =>
              import("@/modules/admin/application/ProductsView.vue"),
            meta: {
              title: "Produtos | Admin | Arbo Monitor",
            },
          },
          {
            path: "equipments",
            name: "admin.equipments",
            component: () =>
              import("@/modules/admin/application/EquipmentsView.vue"),
            meta: {
              title: "Equipamentos | Admin | Arbo Monitor",
            },
          },
          {
            path: "weather-stations",
            name: "admin.stations",
            component: () => import("@/modules/admin/AdminStationsView.vue"),
            meta: {
              title: "Estações | Admin | Arbo Monitor",
            },
          },
          {
            path: "others",
            name: "admin.others",
            component: () =>
              import("@/modules/admin/others/AdminOthersView.vue"),
            meta: {
              title: "Outros | Admin | Arbo Monitor",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/modules/users/LoginView.vue"),
    meta: {
      title: "Arbo Monitor - Login",
    },
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;
